import React, { PropsWithChildren } from 'react';

import { Alert } from 'react-bootstrap';

interface Props extends PropsWithChildren<any> {
  show: boolean;
  onUpdateShow: () => void;
  header?: string;
  message?: string;
}

/**
 * Exported interface for basic Alert
 */
interface AlertInterface {
  show: boolean;
  message: string;
  status: string;
}

const ErrorAlert = (props: Props) => {
  if (props.show) {
    return (
      <div
        style={{
          position: 'sticky',
          bottom: 10,
          left: window.innerWidth,
          zIndex: 2000,
          maxWidth: '472px',
        }}
      >
        <Alert
          variant="danger"
          onClose={() => props.onUpdateShow()}
          dismissible
          style={{
            backgroundColor: '#535353',
            borderColor: '#535353',
            color: '#FFFFFF',
            borderRadius: '13px',
          }}
        >
          <Alert.Heading style={{ fontSize: '18px' }}>
            {props.header}
          </Alert.Heading>
          <p style={{ fontSize: '10px' }}>{props.message}</p>
        </Alert>
      </div>
    );
  }

  return <div></div>;
};

export default ErrorAlert;
export type { AlertInterface };
