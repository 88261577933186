import { Button, Col, Row } from 'react-bootstrap';
import React, { PropsWithChildren } from 'react';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import styles from './css/profileArea.module.css';

interface ProfileAreaProps extends PropsWithChildren<any> {
  username: string;
  handlelogout: () => void;
}

const ProfileArea: React.FC<ProfileAreaProps> = (props: ProfileAreaProps) => {
  return (
    <Row style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
      <Col xs={2}>
        <Button
          variant="link"
          className={styles.button}
          onClick={() => {
            props.handlelogout();
          }}
        >
          Log out
        </Button>
      </Col>
      <Col xs={4}>
        <h3 className={styles.headerTitle}>{props.username}</h3>
      </Col>
      <Col xs={1}>
        <AccountCircleIcon style={{ fontSize: '42px', paddingBottom: '5px' }} />
      </Col>
    </Row>
  );
};

export default ProfileArea;
export type Props = ProfileAreaProps;
