import React, { MouseEventHandler, PropsWithChildren } from 'react';

import { Button } from 'react-bootstrap';

interface Props extends PropsWithChildren<any> {
  onClick: MouseEventHandler;
  // eslint-disable-next-line @typescript-eslint/ban-types
  style?: object;
  color?: string;
}

const VTPButton: React.FC<Props> = (props: Props) => {
  const color = props.color
    ? props.color
    : 'linear-gradient(133.49deg, #222A21 1.95%, #212221 99.13%)';

  return (
    <Button
      onClick={props.onClick}
      variant="outline-primary"
      block
      style={{
        background: color,
        boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.25)',
        borderRadius: '179px',
        borderWidth: '0',
        marginTop: '20px',
        color: '#ffffff',
        fontFamily: 'Nats-Regular',
        fontSize: '22px',
        ...props.style,
      }}
    >
      {props.children}
    </Button>
  );
};

export default VTPButton;
