import 'bootstrap/dist/css/bootstrap.min.css';

import { Button, Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Props } from './profileArea-default';
import React from 'react';
import styles from './css/profileArea.module.css';
import { PropsWithChildren } from 'react';

interface ProfileMobileProps extends PropsWithChildren<any> {
  username: string;
  handlelogout: () => void;
}

const MyPopover = (props: ProfileMobileProps) => {
  return (<Popover id={'bottom'} {...props}>
    <Button
      variant="link"
      className={styles.headerButton}
      onClick={async () => {
        props.handlelogout();
      }}
    >
      <Popover.Title as="h2" className={styles.popoverTitle}>
        Log Out
      </Popover.Title>
    </Button>
  </Popover>);
}

const ProfileAreaMobile: React.FC<Props> = (props: ProfileMobileProps) => {
  return (
    <Row
      noGutters
      style={{ alignItems: 'center', justifyContent: 'flex-end', flex: '1' }}
    >
      <Col xs={8}>
        <h3 className={styles.headerTitle_small}>{props.username}</h3>
      </Col>
      <Col xs={3}>
        <OverlayTrigger
          trigger="click"
          key={'bottom'}
          placement={'bottom'}
          overlay={MyPopover(props)}
          delay={{ show: 250, hide: 400 }}
        >
          <Button variant="link">
            <AccountCircleIcon
              style={{
                fontSize: '42px',
                paddingBottom: '5px',
                color: '#FFFFFF',
              }}
            />
          </Button>
        </OverlayTrigger>
      </Col>
    </Row>
  );
};

export default ProfileAreaMobile;
